const menuItems = [
  {
    path: 'home',
    label: 'Home',
  },
  // {
  //   path: 'testimonials',
  //   label: 'Testimonials',
  // },
  {
    path: 'features',
    label: 'Features',
  },
  // {
  //   path: 'application',
  //   label: 'Screens',
  // },
  {
    path: 'benefits',
    label: 'Benefits',
  },
];
export default menuItems;
