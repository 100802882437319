export const menuItems = [
  {
    id: 1,
    title: '',
    items: [
      {
        path: 'https://clemviews.notion.site/Clem-T-C-153fd1ff79664cf696b768a07535fec9',
        label: 'T&C',
      },
    ],
  },
  {
    id: 2,
    title: '',
    items: [
      {
        path: '#register-interest',
        label: 'Contact us',
      },
      // {
      //   path: '#!',
      //   label: 'About Us',
      // },
      // {
      //   path: '#!',
      //   label: 'Copyright',
      // },
      // {
      //   path: '#!',
      //   label: 'Popular Campaign',
      // },
    ],
  },
];

export const footerNav = [
  // {
  //   path: '#!',
  //   label: 'Home',
  // },
  // {
  //   path: '#!',
  //   label: 'Advertise',
  // },
  // {
  //   path: '#!',
  //   label: 'Supports',
  // },
  // {
  //   path: '#!',
  //   label: 'Marketing',
  // },
  // {
  //   path: '#!',
  //   label: 'FAQ',
  // },
];
