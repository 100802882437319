import React from 'react';

const logo = ({ type }) => {
    const fill = type === 'light' ? '#4C3492' : '#fff';
    
    return (
        <svg width="80" height="80" viewBox="0 0 440 440" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="220" cy="167.285" rx="95" ry="94.7157" fill={fill} />
            <path d="M303.036 67.9196C218.817 43.6822 143.437 65.6758 126.809 148.52C151.495 159.946 256.93 155.1 306.33 76.4101C308.353 73.188 306.693 68.9719 303.036 67.9196Z" fill="#FF9431" />
            <path d="M128.045 342.448H149.67C149.67 337.993 149.225 333.975 148.335 330.393C147.534 326.724 146.066 323.623 143.93 321.09C141.883 318.469 139.08 316.46 135.52 315.062C132.05 313.577 127.644 312.834 122.305 312.834C117.766 312.834 113.539 313.271 109.623 314.145C105.707 315.018 102.281 316.809 99.3443 319.517C96.4965 322.225 94.2272 326.113 92.5363 331.179C90.8454 336.246 90 342.972 90 351.359C90 359.046 90.7564 365.248 92.2693 369.966C93.7822 374.595 95.8736 378.221 98.5434 380.841C101.302 383.375 104.595 385.034 108.422 385.821C112.248 386.607 116.476 387 121.103 387C131.338 387 138.724 384.641 143.263 379.924C145.577 377.391 147.223 374.159 148.202 370.228C149.181 366.297 149.67 361.536 149.67 355.945H128.045V360.531C128.045 364.986 127.422 368.044 126.176 369.703C125.019 371.363 123.328 372.193 121.103 372.193C119.768 372.193 118.611 371.887 117.633 371.276C116.743 370.577 116.031 369.398 115.497 367.738C114.963 366.078 114.562 363.851 114.295 361.055C114.117 358.172 114.028 354.547 114.028 350.179C114.028 345.812 114.117 342.23 114.295 339.434C114.562 336.552 114.963 334.28 115.497 332.621C116.031 330.874 116.743 329.694 117.633 329.083C118.611 328.384 119.768 328.034 121.103 328.034C123.684 328.034 125.464 329.039 126.443 331.048C126.977 332.184 127.377 333.669 127.644 335.503C127.911 337.251 128.045 339.566 128.045 342.448Z" fill={fill} />
            <path d="M156.805 292V385.559H180.833V292H156.805Z" fill={fill} />
            <path d="M225.928 338.124V341.269H212.712V338.124C212.712 334.368 213.157 331.616 214.047 329.869C215.026 328.122 216.761 327.248 219.253 327.248C221.834 327.248 223.569 328.122 224.459 329.869C225.438 331.616 225.928 334.368 225.928 338.124ZM212.712 354.11H248.354V346.903C248.354 340.439 247.731 335.067 246.485 330.786C245.328 326.418 243.504 322.924 241.012 320.303C238.609 317.595 235.584 315.674 231.935 314.538C228.375 313.402 224.192 312.834 219.387 312.834C213.958 312.834 209.286 313.533 205.37 314.931C201.544 316.241 198.384 318.382 195.892 321.352C193.401 324.322 191.576 328.166 190.419 332.883C189.262 337.6 188.684 343.366 188.684 350.179C188.684 356.906 189.173 362.628 190.152 367.345C191.22 372.062 192.956 375.906 195.358 378.876C197.761 381.759 200.921 383.855 204.836 385.166C208.752 386.389 213.602 387 219.387 387C229.354 387 236.518 384.816 240.879 380.448C245.239 375.993 247.509 369.398 247.687 360.662H226.061C226.061 365.467 225.438 368.743 224.192 370.49C223.036 372.149 221.389 372.979 219.253 372.979C217.562 372.979 216.05 372.368 214.715 371.145C213.38 369.834 212.712 367.257 212.712 363.414V354.11Z" fill={fill} />
            <path d="M290.196 338.779V385.559H314.225V338.779C314.225 335.809 314.67 333.669 315.559 332.359C316.449 331.048 317.962 330.393 320.098 330.393C322.234 330.393 323.747 331.048 324.637 332.359C325.527 333.669 325.972 335.809 325.972 338.779V385.559H350V332.097C350 328.253 349.511 325.108 348.532 322.662C347.553 320.216 346.218 318.251 344.527 316.766C342.836 315.28 340.834 314.276 338.52 313.752C336.295 313.14 333.848 312.834 331.178 312.834C327.44 312.834 323.925 313.664 320.632 315.324C317.428 316.897 314.892 319.692 313.023 323.71C311.51 320.041 309.419 317.333 306.749 315.586C304.168 313.752 300.386 312.834 295.402 312.834C286.859 312.834 281.03 316.329 277.915 323.317H277.648V314.669H254.421V385.559H278.449V338.779C278.449 335.809 278.894 333.669 279.784 332.359C280.674 331.048 282.187 330.393 284.323 330.393C286.458 330.393 287.971 331.048 288.861 332.359C289.751 333.669 290.196 335.809 290.196 338.779Z" fill={fill} />
        </svg>
    );
};

export default logo;